<template>
  <v-parallax :src="backgroundImage" style="height: 100%">
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" lg="5" md="6" sm="8">
        <v-card class="elevation-5 pa-3">
          <v-card-actions>
            <v-spacer></v-spacer>
            <localization/>
          </v-card-actions>
          <v-card-text>
            <div class="layout column align-center">
              <img alt="Vue Vuetify Admin Logo" height="120" :src="require('~/img/icons/LOGO.png')" width="120">
              <h1 class="text-center my-4 primary--text">{{ appTitle }}</h1>
            </div>
            <v-form>
              <v-text-field @keyup.enter="onClickChangePassword" v-model="user.password" :label="$t('login.password')"
                            append-icon="mdi-lock" autocomplete="current-password" name="password"
                            required type="password"/>
              <v-text-field @keyup.enter="onClickChangePassword" v-model="user.password_conf" :label="'Password Confirm'"
                            append-icon="mdi-lock" autocomplete="current-password" name="password"
                            required type="password"/>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <template v-if="$vuetify.breakpoint.name === 'xs'">
              <v-row>
                <v-col cols="6">
                  <v-btn :loading="loading" color="primary" class="float-right" @click="onClickChangePassword">
                    Đặt lại mật khẩu
                  </v-btn>
                </v-col>
                <v-col cols="12">
                </v-col>
                <v-col cols="12">
                </v-col>

              </v-row>
            </template>
            <template v-else>
              <v-spacer/>
              <v-btn :loading="loading" color="primary" @click="onClickChangePassword">
                Đặt lại mật khẩu
              </v-btn>
            </template>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>
import Localization from '@/admin/widget/AppLocalization.vue';
import {mapActions, mapGetters} from 'vuex'
import config from '@/config'

export default {
  name: 'Login',
  components: {Localization},
  data: () => ({
    appTitle: config.appName,
    loading: false,
    socialLogin: false,
    user: {
      password: '',
      password_conf: '',
      email: '',
      token: '',
    },
  }),
  computed: {
    ...mapGetters({
      backgroundImage: 'settings/backgroundImage'
    })
  },
  methods: {
    ...mapActions({
      detailReminderPass: 'user/detailReminderPass',
      resetPass: 'user/resetPass',
    }),
    async onClickChangePassword() {
      if (this.user.password_conf === '' || this.user.password === '' || this.user.password_conf !== this.user.password) {
        return this.noticeError('Passwork không được để trống và phải giống  Password Confirm!');
      }
      const res = await this.resetPass(this.user)
      if (!res.data.error) {
        this.noticeSuccess('Reset success!');
        return this.$router.push({name: 'Login'});
      }
    },
    async checkTokenRemainder() {
      console.log(this.$router.currentRoute.params.token, 'token')
      // if (!this.$router.currentRoute.params.token) {
      //   return this.$router.push({name: 'Login'});
      // }
      const res = await this.detailReminderPass(this.$router.currentRoute.params.token)
      if (res.data.error || (res.data.data && !res.data.data.reminder_token) || (res.data.data && !res.data.data.email)) {
        this.noticeError('Token error!');
        return this.$router.push({name: 'Login'});
      } else if (res.data.data) {
        this.user.email = res.data.data.email;
        this.user.token = res.data.data.reminder_token;
      }
    }
  },
  mounted() {
    if (this.$auth.token()) return this.$router.push({name: 'Dashboard'});
    this.$auth.fetch();
    this.checkTokenRemainder();
  },
  beforeCreate() {
    let routeParams = this.$route.params;
    if (routeParams && routeParams.email) {
      this.user.username = routeParams.email
      this.user.password = routeParams.password ?? ''
    }
  }
};
</script>
<style>
.v-parallax__image-container img {
  bottom: 0;
  left: 0;
  filter: brightness(35%);
  position: fixed;
  right: 0;
  top: 0;
  transform: none !important;
  transition: none;
  width: 100%;
  height: 100%;
}
</style>
